import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import { injectIntl, intlShape } from '../../../util/reactIntl';

import { FieldTextInput, LocationAutocompleteInput } from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './LocationFilter.module.css';
import { Field } from 'react-final-form';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { createResourceLocatorString } from '../../../util/routes';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { parse } from '../../../util/urlHelpers';
import { mapboxBoundsToSDKBounds } from '../SearchMap/CustomSearchMapWithMapbox';

const identity = v => v;

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;
// Short search queries (e.g. 2 letters) have a longer timeout before search is made
const TIMEOUT_FOR_SHORT_QUERIES = 2000;

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'keywords';
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;
    this.mobileInputRef = React.createRef();
    this.onChange = this.onChange.bind(this);

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  onChange(location) {
    const { appConfig, onSubmit } = this.props;

    //   onSubmit({ location });
    // blur search input to hide software keyboard
    this.searchInput?.blur();
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      isMobile,
      config,
      location,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const { location: address, bounds: prevBounds, ...otherSearchParams } = parse(location.search);

    const urlParam = getKeywordQueryParam(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'LocationFilter.labelSelected' },
          { labelText: initialValues[urlParam] }
        )
      : label;

    const labelClass = hasInitialValues ? css.labelPlainSelected : css.labelPlain;
    const labelForPlain = <span className={labelClass}>{label}</span>;

    const filterText = intl.formatMessage({ id: 'LocationFilter.filterText' });

    const placeholder = intl.formatMessage({ id: 'LocationFilter.placeholder' });

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: { search: address } };

    const handleSubmit = values => {
      const { currentSearchParams } = this.props;
      const { history, config, routeConfiguration } = this.props;

      const convertToBoundsObject = bounds => {
        const [swLng, swLat] = bounds[0];
        const [neLng, neLat] = bounds[1];

        return new window.mapboxgl.LngLatBounds(
          new window.mapboxgl.LngLat(swLng, swLat),
          new window.mapboxgl.LngLat(neLng, neLat)
        );
      };

      const sdkBoundsToMapboxBounds = bounds => {
        if (!bounds) {
          return null;
        }
        const { ne, sw } = bounds;

        // if sw lng is > ne lng => the bounds overlap antimeridian
        // => flip the nw lng to the negative side so that the value
        // is less than -180
        const swLng = sw.lng > ne.lng ? -360 + sw.lng : sw.lng;

        return [[swLng, sw.lat], [ne.lng, ne.lat]];
      };

      // const calculateExtendedBounds = (bounds, radius) => {
      //   if (!bounds) return;
      //   const { lng, lat } = bounds.getCenter();
      //   const radiusInDegrees = kmToDegrees(radius, lat);

      //   // console.log('Radius in degrees:', radiusInDegrees);
      //   const extendedBounds = new window.mapboxgl.LngLatBounds(
      //     new window.mapboxgl.LngLat(lng - radiusInDegrees, lat - radiusInDegrees),
      //     new window.mapboxgl.LngLat(lng + radiusInDegrees, lat + radiusInDegrees)
      //   );
      //   return extendedBounds;
      // };

      function milesToDegrees(miles, latitude) {
        const milesPerDegreeLat = 69;
        const milesPerDegreeLng = 69 * Math.cos(latitude * (Math.PI / 180)); // Adjusts for latitude

        const latDegrees = miles / milesPerDegreeLat;
        const lngDegrees = miles / milesPerDegreeLng;

        return { latDegrees, lngDegrees };
      }

      function calculateExtendedBounds(bounds, radiusInMiles) {
        if (!bounds) return;
        const radiusInKm = 1.60934 * radiusInMiles;
        const { lng, lat } = bounds?.getCenter();

        const degreePerKmLat = 0.008983;
        const degreePerKmLng = 0.008983 / Math.cos((lat * Math.PI) / 180);

        const deltaLat = radiusInKm * degreePerKmLat;
        const deltaLng = radiusInKm * degreePerKmLng;

        const latNE = lat + deltaLat;
        const lngNE = lng - deltaLng;
        const latSW = lat - deltaLat;
        const lngSW = lng + deltaLng;

        const { latDegrees, lngDegrees } = milesToDegrees(radiusInMiles, lat);

        const extendedBounds = new window.mapboxgl.LngLatBounds(
          new window.mapboxgl.LngLat(lngSW, latSW),
          new window.mapboxgl.LngLat(lngNE, latNE)
        );

        return extendedBounds;
      }

      const kmToDegrees = (km, latitude) => {
        const earthCircumference = 40075.016686;
        const degrees = km / (earthCircumference * Math.cos(latitude * (Math.PI / 180)));
        return degrees;
      };

      const topbarSearchParams = () => {
        if (isMainSearchTypeKeywords(config)) {
          return { keywords: values?.keywords };
        }
        // topbar search defaults to 'location' search
        const { search, selectedPlace } = values?.location || {};
        const { origin, bounds } = selectedPlace || {};
        const originMaybe = isOriginInUse(config) ? { origin } : {};

        const mapBounds = bounds
          ? convertToBoundsObject(sdkBoundsToMapboxBounds(bounds))
          : sdkBoundsToMapboxBounds(bounds);

        const radius = 100; // in miles

        const extendedBounds = mapboxBoundsToSDKBounds(calculateExtendedBounds(mapBounds, radius));

        // console.log(bounds, extendedBounds, mapBounds);
        return {
          ...originMaybe,
          location: search,
          bounds,
          // bounds: bounds ? extendedBounds : prevBounds,
        };
      };
      const searchParams = {
        ...otherSearchParams,
        ...currentSearchParams,
        ...topbarSearchParams(),
      };
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    };

    const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
      leading: false,
      trailing: true,
    });
    // Use timeout for shart queries and debounce for queries with any length
    const handleChangeWithDebounce = values => {
      // handleSubmit gets values as params.
      // If this field ("keyword") is short, create timeout
      const hasKeywordValue = values && values[name];
      const keywordValue = hasKeywordValue ? values && values[name] : '';
      if (!hasKeywordValue || (hasKeywordValue && keywordValue.length >= 3)) {
        if (this.shortKeywordTimeout) {
          window.clearTimeout(this.shortKeywordTimeout);
        }
        return debouncedSubmit(values);
      } else {
        this.shortKeywordTimeout = window.setTimeout(() => {
          // if mobileInputRef exists, use the most up-to-date value from there
          return this.mobileInputRef && this.mobileInputRef.current
            ? handleSubmit({ ...values, [name]: this.mobileInputRef.current.value })
            : handleSubmit(values);
        }, TIMEOUT_FOR_SHORT_QUERIES);
      }
    };

    // Uncontrolled input needs to be cleared through the reference to DOM element.
    const handleClear = () => {
      const { history, routeConfiguration } = this.props;
      if (this.mobileInputRef && this.mobileInputRef.current) {
        this.mobileInputRef.current.value = '';
      }

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration, {}, { ...otherSearchParams })
      );
    };

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        onClear={handleClear}
        customClear={true}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <div className={css.lbl}>Search by location</div>
        <Field
          name="location"
          format={identity}
          render={({ input, meta }) => {
            const { onChange, ...restInput } = input;

            // Merge the standard onChange function with custom behaviur. A better solution would
            // be to use the FormSpy component from Final Form and pass this.onChange to the
            // onChange prop but that breaks due to insufficient subscription handling.
            // See: https://github.com/final-form/react-final-form/issues/159
            const searchOnChange = value => {
              onChange(value);
              this.onChange(value);
            };

            return (
              <LocationAutocompleteInput
                className={css.field}
                iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
                predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
                placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                closeOnBlur={!isMobile}
                inputRef={this.setSearchInputRef}
                input={{ ...restInput, onChange: searchOnChange }}
                meta={meta}
                hideIcon={true}
              />
            );
          }}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleChangeWithDebounce}
        onClear={handleClear}
        initialValues={namedInitialValues}
        {...rest}
      >
        <fieldset className={css.fieldPlain}>
          {/* <label className={css.fieldPlainLabel}>{filterText}</label> */}
          <Field
            name="location"
            format={identity}
            render={({ input, meta }) => {
              const { onChange, ...restInput } = input;

              // Merge the standard onChange function with custom behaviur. A better solution would
              // be to use the FormSpy component from Final Form and pass this.onChange to the
              // onChange prop but that breaks due to insufficient subscription handling.
              // See: https://github.com/final-form/react-final-form/issues/159
              const searchOnChange = value => {
                onChange(value);
                this.onChange(value);
              };

              return (
                <LocationAutocompleteInput
                  className={css.field}
                  iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                  inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                  predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
                  predictionsAttributionClassName={
                    isMobile ? css.mobilePredictionsAttribution : null
                  }
                  placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                  closeOnBlur={!isMobile}
                  inputRef={this.setSearchInputRef}
                  input={{ ...restInput, onChange: searchOnChange }}
                  meta={meta}
                  hideIcon={true}
                />
              );
            }}
          />
        </fieldset>
      </FilterPlain>
    );
  }
}

export default compose(
  withRouter,
  injectIntl
)(LocationFilter);
